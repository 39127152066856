<template>
  <div class="myPageSite">
<!--    <div class="backHome">-->
      <router-link to="/" tag="div" class="backHome"><img src="../assets/svg/arrowBack.svg" alt="">Главная</router-link>
<!--    </div>-->
    <div class="myForm">
      <div class="title">Написать нам</div>
      <div class="description">
        Расскажите нам о своей проблеме  <span class="brLine"><br></span> и мы найдём <br>
        её решение.
        <div class="errorText" v-if="errorSend">Для связи необходимо указать имя и предпочитаемый вид связи.</div>
      </div>

      <div class="inputGroup">
        <input type="text" @input="deleteError('name')" v-model="form.name" placeholder="Ваше имя"
               :class="error.name?'error':''">
      </div>
      <div class="inputGroup">
        <input type="text" @input="deleteError()" v-model="form.phone" placeholder="Телефон"
               :class="error.phone?'error':''">
      </div>
      <div class="inputGroup">
        <input type="text"  v-model="form.email" placeholder="E-mail">
      </div>
      <div class="inputGroup">
        <select v-model="form.type">
          <option value="">Выберите практику</option>
          <option value="1">Арбитражные споры</option>
          <option value="2">Налоговы споры</option>
          <option value="3">Исполнительное производство</option>
          <option value="4">Авторское право</option>
          <option value="5">Внешнеэкономическая деятельность</option>
          <option value="6">Семейный дела</option>
          <option value="7">Уголовные дела</option>
        </select>
      </div>
      <div class="inputGroup">
        <input type="text" v-model="form.question" @input="deleteError()" placeholder="Задайсте свой вопрос" :class="error.question?'error':''">
      </div>
      <div class="inputText">
        <p>Нажимая на кнопку «Отправить» <span class="brLine"><br></span> вы соглашаетесь с <br> <span @click="saveDocument()">политикой конфиденциальности</span>
        </p>
      </div>
      <div class="buttonLine">
        <button @click="sendForm">Отправить</button>
      </div>

    </div>
    <transition name="opacity" mode="out-in">
      <politics v-if="visualPolitics" @close="visualPolitics = false"/>
    </transition>
  </div>

</template>

<script>
import Politics from "@/components/politics";

export default {
  name: "feedback",
  components: {Politics},
  data() {
    return {
      visualPolitics: false,
      form: {
        name: '',
        phone: '',
        email: '',
        type: '',
        question: '',
      },
      error: {
        name: false,
        phone: false,
        question: false,
      },
      errorSend: false,
    }
  },
  methods: {
    deleteError(type) {
      this.errorSend = false;
      if (type === 'name') this.error.name = false;
      if (type === 'phone') this.error.phone = false;
      if (type === 'question') this.error.question = false;
    },
    saveDocument() {
      this.visualPolitics = true
    },
    async sendForm() {
      ym(69927298,'reachGoal','test');
      let error = false;
      if (this.form.name === '') {
        this.error.name = true;
        error = true;
      }
      if (this.form.phone === '') {
        this.error.phone = true;
        error = true;
      }
      if (this.form.question === '') {
        this.error.question = true;
        error = true;
      }
      if (!error) {

        let response = await fetch('http://slavadev.ru/send.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify(this.form)
        });
        alert('Сообщение успешно отправлено.');
      } else {
        this.errorSend = true;
      }
    }
  },
  created() {
    document.title = 'IzvarinLaw - Связяться с нами';
  }
}
</script>

<style scoped lang="sass">
  =r($width)
    @media only screen and (max-width: $width+ "px")
      @content
  .backHome
    padding: 20px 0 40px
    display: flex
    align-items: center
    color: #fff
    text-decoration: none
    font-weight: 400
    font-size: 26px
    cursor: pointer
    +r(1400)
      font-size: 22px
      padding: 10px 0 30px
    img
      margin-right: 20px
    +r(750)
      display: none

  .myPageSite
    color: #fff
    padding: 50px 0 70px 200px
    background-color: #005546
    width: 100%
    +r(1400)
      padding: 30px 0 50px 200px
    +r(1200)
      padding: 30px 80px 70px
    +r(750)
      padding: 70px 80px
    +r(500)
      padding: 70px 30px
  .myForm
    width: 550px
    position: relative
    +r(998)
      width: 100%
  .title
    font-family: 'Montserrat', sans-serif
    font-size: 34px
    font-weight: 600
    margin: 0 0 17px
    padding: 0
    +r(1400)
      font-size: 30px
      margin: 0 0 10px
    +r(998)
      font-size: 24px

  .description
    position: relative
    font-size: 18px
    font-family: 'Montserrat', sans-serif
    padding: 0
    margin: 0
    line-height: 1.5
    margin-bottom: 15px
    +r(998)
      font-size: 16px
    br
      +r(850)
        display: none
      +r(750)
        display: block
      +r(650)
        display: none
  input, select
    font-family: 'Montserrat', sans-serif
    background-color: transparent
    border: none
    border-bottom: 1px solid #aeaeae
    color: #fff
    padding: 7px 0
    outline: none
    font-size: 18px
    width: 100%
    +r(998)
      font-size: 16px
    &::placeholder
      color: #fff
      opacity: .3
      font-weight: 400
  .error
    &::placeholder
      color: #FF3D12
      font-weight: 400
      opacity: 1
  .inputGroup
    padding-top: 40px
    +r(1400)
      padding-top: 25px
  .brLine
    br
      display: none
      +r(850)
        display: block
      +r(750)
        display: none
      +r(600)
        display: block
  .inputText
    padding-top: 20px
    line-height: 1.5
    p
      font-family: 'Montserrat', sans-serif

    span
      font-family: 'Montserrat', sans-serif
      color: #DB6500

      &:hover
        text-decoration: underline
        cursor: pointer
  button
    font-family: 'Montserrat', sans-serif
    margin-top: 20px
    padding: 15px 30px
    background-color: #DB6500
    color: #fff
    font-size: 22px
    border: none
    outline: none
    border-radius: 3px
    +r(750)
      margin-top: 10px
  option
    color: #000
    background-color: transparent
  .buttonLine
    position: relative
  .errorText
    position: absolute
    font-size: 14px
    left: 0
    top: 110%
    font-family: 'Montserrat', sans-serif
    color: #FF3D12
    font-weight: 400

</style>
